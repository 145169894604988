import UrlConstants from "../constants/url-configs";
import GenericConstants from "../constants/constants";
import * as ApiCallUtil from "./generic-api-utils";
import axios, {AxiosError} from "axios";
import { parseJwt } from '../helpers/scripts';

export const viewSalesSummery = async (
  filter,
  pageNumber,
  genericSearchPageLimit,
  type
) => {
  console.log(filter);
  let url;
  if(type === 'download'){
    url = UrlConstants.REPORTS.DOWNLOAD_SALES_SUMMARY;
  } else if (type === 'all'){
    url =  UrlConstants.REPORTS.SALES_SUMMARY;   

  }else {
    url =  UrlConstants.REPORTS.SALES_SUMMARY +  `?page=${pageNumber}&size=${genericSearchPageLimit}`;   
  }
  const message = 'sales summary response '
  return await AxiosFunc(url,message,filter)
};

// Users

// export const getAllUsers = async () => {
//   let url = UrlConstants.SETUP.VIEW_USERS
//   let filter = null;
//   let brand = true
//   const message = 'Users response '
//   return await AxiosFunc(url,message,filter,brand)
// }

export const getAllUsers = async (PageNumber,limit) => {
  const url =
  UrlConstants.SETUP.VIEW_USERS + `?limit=${limit}&page=${PageNumber}`;
const callType = GenericConstants.API_CALL_TYPE.GET;
return await ApiCallUtil.http(
  url, 
  callType 
)
};
// categories

export const getAllCategories = async (all,limit,PageNumber) => {
  let url;
   if(all === 'all'){
     url = UrlConstants.CATEGORIES.SEARCH + `?limit=${limit}&page=${PageNumber}`;
   }
   else url = UrlConstants.CATEGORIES.SEARCH;
   const callType = GenericConstants.API_CALL_TYPE.GET;
 
   return await ApiCallUtil.http(
     url,
     callType,
   );
 };

 export const fetchCategoriesById = async (id) => {
  const  url = UrlConstants.REPORTS.GET_CATEGORY_BY_ID + `/${id}`;
    const callType = GenericConstants.API_CALL_TYPE.GET;
  
    return await ApiCallUtil.http_report(
      url,
      callType,
    );
  };

  export const downloadCategory = async (id)=> {
    const  url = UrlConstants.REPORTS.DOWNLOAD_CATEGORY + `/${id}`;
    const callType = GenericConstants.API_CALL_TYPE.GET;
  
    return await ApiCallUtil.http_report(
      url,
      callType,
    );
  }

export const viewCategoryWiseSalesSummery = async (startDate, endDate) => {
  const formDataPair = {
    startDate: startDate,
    finishDate: endDate,
  };
  const categoryWiseSalesSummeryFormDataBody =
    ApiCallUtil.constructFormData(formDataPair);
  const url = UrlConstants.REPORTS.CATEGORY_SALES_SUMMARY;
  const callType = GenericConstants.API_CALL_TYPE.POST;

  return await ApiCallUtil.http_report(
    url, //api url
    callType, //calltype
    categoryWiseSalesSummeryFormDataBody //body
  );
};

export const viewPrductsInventory = async (type) => {
  let url;
  if(type === 'download'){
   url = UrlConstants.REPORTS.EXPORT_INVENTORY_DUMP

  } else {
  url =UrlConstants.REPORTS.INVENTORY_DUMP

  }
  const message = 'Inventory dump report'
    return await AxiosFunc(url,message)
};

export const getStoreId = async () => {
  const url = UrlConstants.REPORTS.GET_STORE;
  const callType = GenericConstants.API_CALL_TYPE.GET;

  return await ApiCallUtil.http_report(
    url, //api url
    callType //calltype
  );
};

export const exportInventoryDumpData = async (startDate, endDate) => {

  const userAuth = ApiCallUtil.getUserAuth();

  const url =
    UrlConstants.REPORTS.EXPORT_INVENTORY_DUMP
    // `?startDate=${startDate}&endDate=${endDate}`;

  let headers = {
    Authorization: `Bearer ${ApiCallUtil.getUserAuthToken()}`,
  };

  if (userAuth?.brand_id) {
    headers["brand_id"] = userAuth?.brand_id;
  }

  if (userAuth?.store_id) {
    headers["store_id"] = userAuth?.store_id;
  }


  return await axios
    .get(url, {
      headers: headers,
    })
    .then(async (res) => {
      let responseData = res?.data;
      if (responseData?.success) {
        console.log("Inventory Dump Import Data Response-> ", responseData);
        return {
          hasError: false,
          message: "Inventory Succesfully Exported",
          ...responseData,
        };
      } else {
        console.log("Cannot Export Inventory Dump Data -> ", responseData);
        return {
          hasError: true,
          errorMessage: responseData.message,
        };
      }
    })
    .catch((error) => {
      console.log("AXIOS ERROR: ", error);
      return { hasError: true, errorMessage: error };
    });
};

export const importSalesSummayDumpReport = async (params) => {
  let query = Object.keys(params)
    .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
    .join("&");

  const url = UrlConstants.REPORTS.IMPORT_SALES_SUMMARY + "?" + query;
  const userAuth = ApiCallUtil.getUserAuth();
  let headers = {
    Authorization: ApiCallUtil.getUserAuthToken(),
  };

  if(userAuth?.brand_id){
    headers["Brand_id"] = userAuth?.brand_id;
  }
  
  if(userAuth?.store_id){
    headers["Store_id"] = userAuth?.store_id;
  }

  return await axios
    .get(url, {
      headers: headers,
    })
    .then(async (res) => {
      console.log("Sales Dump Export Data Response -> ", res);
      return {
        hasError: false,
        message: "Sales Report Succesfully Imported",
        data: res.data,
      };
    })
    .catch((error) => {
      console.log("AXIOS ERROR: ", error);
      return { hasError: true, errorMessage: error };
    });
};

export const importOmniSalesSummayDumpReport = async (params) => {
  let query = Object.keys(params)
    .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
    .join("&");

  const url = UrlConstants.REPORTS.IMPORT_SALES_OMNI_SUMMARY + "?" + query;
  const userAuth = ApiCallUtil.getUserAuth();
  let headers = {
    Authorization: ApiCallUtil.getUserAuthToken(),
  };

  if(userAuth?.brand_id){
    headers["Brand_id"] = userAuth?.brand_id;
  }
  
  if(userAuth?.store_id){
    headers["Store_id"] = userAuth?.store_id;
  }

  return await axios
    .get(url, {
      headers: headers,
    })
    .then(async (res) => {
      console.log("Sales Omni Dump Export Data Response -> ", res);
      return {
        hasError: false,
        message: "Sales Report Succesfully Imported",
        data: res.data,
      };
    })
    .catch((error) => {
      console.log("AXIOS ERROR: ", error);
      return { hasError: true, errorMessage: error };
    });
};

const isNetworkError = err =>  !!err.isAxiosError && !err.response;

const AxiosFunc = async (url,message,filter)=> {
  const userAuth = ApiCallUtil.getUserAuth();
  const decodedToken = parseJwt(userAuth?.auth_token)

  let headers = {
    Authorization: `Bearer ` + ApiCallUtil.getUserAuthToken(),
    ...(filter && {filter: JSON.stringify(filter)}),
  };

  if(userAuth?.brand_id){
    headers["brand_id"] = userAuth?.brand_id;
  }
  
  if(userAuth?.store_id){
    headers["store_id"] = userAuth?.store_id;
  }
  
  if(decodedToken?.company_id){
    headers["company_id"] = decodedToken?.company_id
  
}
  try {
    const res = await axios.get(url, {headers})
      const responseData = res?.data ;
      if (responseData?.success) {
        return {
          hasError: false,
          message,
          ...responseData,
        };
      } else { 
        return {
          hasError: true,
          errorMessage: responseData.message,
        };
      }
  } catch (error) {
    if (isNetworkError(error)) {
      return { hasError: true, errorMessage: 'Network Error' }
    }
    else {
     return { hasError: true, errorMessage: error?.response?.data?.message };
    }
  }
  
   
}
// OrderWise Report
export const orderWiseReport = async (filter, page,pagination,type) => {
  let url;
  if(type === 'download'){
    url = UrlConstants.REPORTS.ORDER_WISE_DOWNLOAD;
  } else {
     url = UrlConstants.REPORTS.ORDER_WISE + `?page=${page}&size=${pagination}`;
  }
  const message = 'Order wise response'
  return await AxiosFunc(url,message,filter)
}

// Platform-wise-Report
export const platformWiseReport = async (filter, page,pagination,type) => {
  let url;
  if(type === 'download'){
    url = UrlConstants.REPORTS.SALE_PLATFORM_DOWNLOAD;
  } else {
   url = UrlConstants.REPORTS.SALE_PLATFORM + `?page=${page}&size=${pagination}`;
  }
  const message = 'Platform-wise response'
  return await AxiosFunc(url,message,filter)
  
}

// Customer-Details-Report 
export const customerDetailsReport = async (filter, page,pagination, customerId,type) => {
  let url;
  if(type === 'download'){
    url = UrlConstants.REPORTS.CUSTOMER_DETAILS_DOWNLOAD + `?cId=${customerId}`;
  } else {
    url = UrlConstants.REPORTS.CUSTOMER_DETAILS + `?page=${page}&size=${pagination}&cId=${customerId}`;
  }
  const message = 'customer details response'
  return await AxiosFunc(url,message,filter)
}

// search single customer report
export const singleCustomerReport = async (filter, page,pagination,customerId,type) => {
  let url;
  if(type === 'download'){
    url = UrlConstants.REPORTS.CUSTOMER_WISE_DOWNLOAD + `?cId=${customerId}`;
  } else {
    url = UrlConstants.REPORTS.CUSTOMER_WISE_VIEW + `?page=${page}&size=${pagination}&cId=${customerId}`;
  }
  const message = 'single customer response'
  return await AxiosFunc(url,message,filter)
}

// search single supplier report
export const singleSupplierReport = async (filter, page,pagination,type) => {
  let url;
  if(type === 'download'){
    url = UrlConstants.REPORTS.SUPPLIER_WISE_DOWNLOAD;
  } else {
    url = UrlConstants.REPORTS.SUPPLIER_WISE_VIEW + `?page=${page}&size=${pagination}`;
  }
  const message = 'single supplier response'
  return await AxiosFunc(url,message,filter)
}

// item-group-wise report 

export const itemWiseReport = async (filter, page,pagination,itemId,type) => {
  let url;
  if(type === 'download'){
    url = UrlConstants.REPORTS.ITEM_WISE_DOWNLOAD + `?itemId=${itemId}`;
  } else {
    url = UrlConstants.REPORTS.ITEM_WISE_VIEW + `?page=${page}&size=${pagination}&itemId=${itemId}`;
  }
  const message = 'customer details response'
  return await AxiosFunc(url,message,filter)
}


export const itemGroupWiseReport = async (filter, page,pagination,catId,type) => {
  let url;
  if(type === 'download'){
    url = UrlConstants.REPORTS.GROUP_WISE_DOWNLOAD + `?catId=${catId}`;
  } else {
    url = UrlConstants.REPORTS.GROUP_WISE_VIEW + `?page=${page}&size=${pagination}&catId=${catId}`;
  }
  const message = 'customer details response'
  return await AxiosFunc(url,message,filter)
}
const returnType =(type,Id)=>{
  console.log(type,Id);
  const id = 'all'
  switch (type) {
    case 'all': {
      return `customerId=${Id}&sku=${id}&orderId=${id}`
    }
    case 'customer': {
      return `customerId=${Id}&sku=${id}&orderId=${id}`
    }
    case 'product':{
      return `customerId=${id}&sku=${Id}&orderId=${id}`
    }
    case 'order':{
      return `customerId=${id}&sku=${id}&orderId=${Id}`
    }
    default:
      return;
  }
}
export const wastageReport = async (filter, page,pagination,searchParameters,type) => {
  let url;
  console.log(searchParameters);
  const id = searchParameters.id
  const name = searchParameters.name
  const checkType = returnType(name,id)
  if(type === 'download'){
    url = UrlConstants.REPORTS.WASTAGE_DOWNLOAD + '?' + checkType;
  } else {
    url = UrlConstants.REPORTS.WASTAGE_VIEW + `?page=${page}&size=${pagination}&` + checkType;
  }
  console.log(url);
  const message = 'customer details response'
  return await AxiosFunc(url,message,filter)
}

// product performance report


export const productPerformanceReport = async (filter, page,pagination,type) => {
  let url;
  if(type === 'download'){
    url = UrlConstants.REPORTS.PRODUCT_PERFORMANCE_DOWNLOAD;
  } else {
   url = UrlConstants.REPORTS.PRODUCT_PERFORMANCE_VIEW + `?page=${page}&size=${pagination}`;
  }
  const message = 'Product performance report'
  return await AxiosFunc(url,message,filter)
  
}


export const purchaseOrdersReport = async (filter, page,pagination,type) => {
  let url;
  if(type === 'download'){
    url = UrlConstants.REPORTS.PURCHASE_ORDER_DOWNLOAD + `?startDate=${filter.startDate}&finishDate=${filter.endDate}`;
  } else {
    url = UrlConstants.REPORTS.PURCHASE_ORDER_VIEW + `?page=${page}&size=${pagination}&startDate=${filter.startDate}&finishDate=${filter.endDate}`;
  }
  const message = 'single supplier response'
  return await AxiosFunc(url,message)
}


export const combineReport = async (month) => {
  
  let  url = UrlConstants.REPORTS.SALES_COMBINED_REPORT + `?month=${month.id}`;
  
  const message = 'combine report'
  return await AxiosFunc(url,message)
}


export const combineINVReport = async (month) => {
  
  let  url = UrlConstants.REPORTS.INVENTORY_COMBINED_REPORT + `?month=${month.id}`;
  
  const message = 'combine report'
  return await AxiosFunc(url,message)
}


export const grnReport = async (filter, page,pagination,type) => {
  let url;
  if(type === 'download'){
    url = UrlConstants.REPORTS.GRN_DOWNLOAD + `?startDate=${filter.startDate}&endDate=${filter.endDate}`;
  } else {
    url = UrlConstants.REPORTS.GRN_VIEW + `?page=${page}&size=${pagination}&startDate=${filter.startDate}&endDate=${filter.endDate}`;
  }
  const message = 'grn report response'
  return await AxiosFunc(url,message)
}

export const poReportDownload = async (startDate, finishDate) => {
  
  let  url = UrlConstants.REPORTS.PO_DOWNLOAD + `?startDate=${startDate}&endDate=${finishDate}`;
  
  const message = 'po report response'
  return await AxiosFunc(url,message)
}

export const strReportDownload = async (startDate, finishDate) => {
  
  let  url = UrlConstants.REPORTS.STR_DOWNLOAD + `?startDate=${startDate}&endDate=${finishDate}`;
  
  const message = 'str report response'
  return await AxiosFunc(url,message)
}